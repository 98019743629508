import Input from "../../../components/Input";
import Select from "../../../components/Select";

const Fields2 = ({ names, handleUpdate, errors }) => {
	return (
		<>
			<h3>
				Does your organization require that all Board of Directors financially
				support the organization?
			</h3>

			<div className="two" style={{ marginTop: "0" }}>
				<div>
					<Select
						label="Select"
						req={true}
						name="support"
						value={names.support}
						data={[
							{ value: "", name: "--" },
							{ value: "Yes", name: "Yes" },
							{ value: "No", name: "No" },
						]}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
				<div className="empty" />
			</div>

			<h3 style={{ marginTop: "20px" }}>
				How many Board members does your organization have?
			</h3>

			<div className="two" style={{ marginTop: "0" }}>
				<div>
					<Input
						type="text"
						label="Enter a single number"
						req={true}
						name="members"
						value={names.members || ""}
						update={handleUpdate}
						errors={errors}
						note=""
					/>
				</div>
				<div className="empty" />
			</div>

			<h3 style={{ marginTop: "20px" }}>
				How many of your Board members personally support your organization by
				making a donation?
			</h3>

			<div className="two" style={{ marginTop: "0" }}>
				<div>
					<Input
						type="text"
						label="Enter only a single number"
						req={true}
						name="personally"
						value={names.personally || ""}
						update={handleUpdate}
						errors={errors}
						note=""
					/>
				</div>
				<div className="empty" />
			</div>
		</>
	);
};

export default Fields2;
