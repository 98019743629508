import Input from "../../../components/Input";

const Fields2 = ({ names, handleUpdate, errors }) => {
	return (
		<>
			<h3>Amount of Grant Request</h3>

			<div className="two" style={{ marginTop: "0" }}>
				<div>
					<Input
						type="text"
						label="Enter a single $ amount"
						req={true}
						name="amount"
						value={names.amount || ""}
						update={handleUpdate}
						errors={errors}
						note=""
					/>
				</div>
				<div className="empty" />
			</div>

			<h3 style={{ marginTop: "20px" }}>
				How many children would grant support if awarded in total?
			</h3>

			<div className="two" style={{ marginTop: "0" }}>
				<div>
					<Input
						type="text"
						label="Enter only a single number"
						req={true}
						name="children"
						value={names.children || ""}
						update={handleUpdate}
						errors={errors}
						note=""
					/>
				</div>
				<div className="empty" />
			</div>

			<h3 style={{ marginTop: "20px" }}>
				What is the cost per child to support this grant?
			</h3>

			<div className="two" style={{ marginTop: "0" }}>
				<div>
					<Input
						type="text"
						label="Enter a single $ amount"
						req={true}
						name="cost"
						value={names.cost || ""}
						update={handleUpdate}
						errors={errors}
						note=""
					/>
				</div>
				<div className="empty" />
			</div>

			<h3 style={{ marginTop: "20px" }}>
				What is the timetable for completion of CHC grant request?
			</h3>

			<div className="one-full" style={{ marginTop: "0" }}>
				<Input
					type="text"
					label="Limit answer to no more than 50 words"
					req={true}
					name="timetable"
					value={names.timetable || ""}
					update={handleUpdate}
					errors={errors}
					note=""
				/>
			</div>
		</>
	);
};

export default Fields2;
