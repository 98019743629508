import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/pro-light-svg-icons";

const Table = ({ sections, status }) => {
	return (
		<>
			<p>
				Click to select a Section / Step from the table below. Once you have
				completed all sections, you will be able to submit your application.
			</p>

			<table className="order">
				<thead>
					<tr>
						<th style={{ width: "10%" }}>Status</th>
						<th className="left">Section / Step</th>
						<th style={{ width: "15%" }}>Updated</th>
					</tr>
				</thead>

				<tbody>
					{sections.map((item, index) => {
						return (
							<tr key={index}>
								<td className="grants">
									{status[item.key] ? (
										<FontAwesomeIcon
											icon={faCircleCheck}
											size="lg"
											style={{ color: "var(--success)" }}
										/>
									) : (
										<FontAwesomeIcon
											icon={faCircleXmark}
											size="lg"
											style={{ color: "var(--error)" }}
										/>
									)}
								</td>
								<td className="grants left">
									<Link
										to={
											item.key.includes("upload")
												? `/upload/${item.key}`
												: `/${item.key}`
										}
									>
										{item.descr}
									</Link>
								</td>
								<td className="grants">{status[item.key]}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
};

export default Table;
