import Input from "../../../components/Input";

const Fields4 = ({ names, handleUpdate, errors }) => {
	return (
		<>
			<div className="two">
				<div>
					<Input
						type="text"
						label="Name of Contact Person (Writing Grant)"
						req={true}
						name="contactName"
						value={names.contactName}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
				<div>
					<Input
						type="text"
						label="Title of Contact Person"
						req={true}
						name="contactTitle"
						value={names.contactTitle}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
			</div>

			<div className="two">
				<div>
					<Input
						type="text"
						label="Contact Person Email"
						req={true}
						name="contactEmail"
						value={names.contactEmail}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
				<div>
					<Input
						type="text"
						label="Contact Person Phone"
						req={true}
						name="contactPhone"
						value={names.contactPhone}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
			</div>
		</>
	);
};

export default Fields4;
