const Fields5 = ({ names }) => {
	const areas = names.areas.split(",");

	return (
		<>
			<p>
				Please note that answering this question will not have any negative
				impact on your grant application.
			</p>

			<h3>
				Please select if your organization could benefit from assistance in one
				or more of these areas.
			</h3>

			<div className="grants areas" style={{ marginTop: "10px" }}>
				{[
					{ id: "a", text: "Grant Writing" },
					{ id: "b", text: "Program Development" },
					{ id: "c", text: "Capacity Building" },
					{ id: "d", text: "Talent Development" },
					{ id: "e", text: "Recruitment and Retention" },
					{ id: "f", text: "Technology" },
					{ id: "g", text: "Website Development" },
					{ id: "h", text: "Social Media" },
					{ id: "i", text: "Overall Marketing Planning" },
					{ id: "j", text: "Newsletter" },
					{ id: "k", text: "Public Relations" },
					{ id: "l", text: "Volunteer Selection" },
					{ id: "m", text: "Special Events" },
					{ id: "n", text: "Sponsorships" },
				].map((item) => {
					return (
						<label htmlFor={item.id} key={item.id}>
							<input
								type="checkbox"
								name={`areas[${item.id}]`}
								id={item.id}
								value={item.text}
								defaultChecked={areas.includes(item.text) ? true : false}
							/>
							<span>{item.text}</span>
						</label>
					);
				})}
			</div>
		</>
	);
};

export default Fields5;
