import React from "react";
import "./styles.css";

function isFieldError(array, val) {
	return array.includes(val);
}

const Input = ({
	type,
	label,
	note = "",
	req,
	name,
	value,
	update,
	errors,
}) => {
	return (
		<div className="input" name={name}>
			<label htmlFor={name} className={!label ? "hidden" : ""}>
				{`${label}`} {req ? <span className="error">*</span> : null}
			</label>
			<input
				type={type}
				name={name}
				id={name}
				defaultValue={value || ""}
				onChange={(e) => (update ? update(name, e.target.value) : null)}
				autoComplete="off"
				className={isFieldError(errors, name) ? "error-border" : ""}
			/>
			<small className={note ? "" : "hidden"}>{note}</small>
		</div>
	);
};

export default Input;
