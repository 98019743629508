import Textarea from "../../../components/Textarea";

const Fields1 = ({ names, handleUpdate, errors }) => {
	return (
		<>
			<div>
				<div>
					<Textarea
						label="Summary in one sentence of the program to be supported by the grant."
						req={true}
						name="summary"
						value={names.summary}
						update={handleUpdate}
						errors={errors}
						note="Limited to 225 characters"
						h3={true}
					/>
				</div>
			</div>

			<div>
				<div>
					<Textarea
						label="Full description of program and purpose of the grant request including goals and objectives."
						req={true}
						name="description"
						value={names.description}
						update={handleUpdate}
						errors={errors}
						note=""
						h3={true}
					/>
				</div>
			</div>
		</>
	);
};

export default Fields1;
