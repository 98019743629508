import Select from "../../../components/Select";
import Input from "../../../components/Input";
import Textarea from "../../../components/Textarea";

const Fields4 = ({ names, handleUpdate, errors, COUNTIES }) => {
	return (
		<>
			<h3>
				Please select the Florida Counties to be served by this particular grant
				request.
			</h3>

			<div className="two" style={{ marginTop: "0" }}>
				<div>
					<Select
						label="Select"
						req={true}
						name="selectRegion"
						value={""}
						data={[{ value: "", name: "--" }].concat(
							COUNTIES.map((county) => {
								return { value: county, name: county };
							})
						)}
						update={handleUpdate}
						errors={errors}
					/>
				</div>

				<Input
					type="text"
					label="Selected Regions"
					req={true}
					name="regions"
					value={names.regions || ""}
					update={handleUpdate}
					errors={errors}
					note=""
				/>
			</div>

			<h3 style={{ marginTop: "20px" }}>
				If your Children’s Healthcare Charity request is not fully funded, what
				will your organization do with the grant monies received?
			</h3>

			<div className="two" style={{ marginTop: "0" }}>
				<div>
					<Input
						type="text"
						label="Limit answer to no more than 50 words"
						req={true}
						name="notFunded"
						value={names.notFunded || ""}
						update={handleUpdate}
						errors={errors}
						note=""
					/>
				</div>
				<div className="empty" />
			</div>

			<Textarea
				label="Mission Statement or Brief History of Organization"
				req={true}
				name="mission"
				value={names.mission}
				update={handleUpdate}
				errors={errors}
				note="Limited to 250 words"
				h3={true}
			/>
		</>
	);
};

export default Fields4;
