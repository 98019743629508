export const API_URL = "https://childrenshealthcarecharity.org/k-api";
export const API_URL_CC =
	"https://childrenshealthcarecharity.org/k-coutoureclub/api";
export const API_URL_JRCC =
	"https://childrenshealthcarecharity.org/k-jrcoutoureclub/api";
export const API_URL_AC =
	"https://childrenshealthcarecharity.org/k-ambassadorsclub/api";
export const API_URL_BFC = "https://childrenshealthcarecharity.org/k-bfc/api";
export const API_URL_WLF =
	"https://childrenshealthcarecharity.org/k-wlftickets/api";
export const API_URL_VKS =
	"https://childrenshealthcarecharity.org/k-vkscholarship/api";
export const API_URL_KICKOFF =
	"https://childrenshealthcarecharity.org/k-kickoff/api";
export const API_URL_DONATE =
	"https://childrenshealthcarecharity.org/k-donate/api";
export const API_URL_CONTACT =
	"https://childrenshealthcarecharity.org/k-contact/api";
export const API_URL_GRANTS =
	"https://childrenshealthcarecharity.org/k-grants/api";

//export const ZMSTER_API_URL = "https://api.sandbox.domain.com";

/*
	export const RECAPTCHA_KEY = "6LcVoMcUAAAAAEaWZppDop2tt9jUyclvGjyntdZO";
	export const AUTHNET_SCRIPT = "https://js.authorize.net/v1/Accept.js";
	export const AUTHNET_LOGINID = "879kHPaH";
	export const AUTHNET_PUBKEY =
		"426zXcntDAV86x2Z8gF2FT7bheDR4cHDq32Sfcc77aFYSz2PB7LVy59aj9FHsb3N";
*/

/* Authnet / Production
  export const AUTHNET_SCRIPT = "https://js.authorize.net/v1/Accept.js";
  export const AUTHNET_LOGINID = "879kHPaH";
  export const AUTHNET_PUBKEY = "426zXcntDAV86x2Z8gF2FT7bheDR4cHDq32Sfcc77aFYSz2PB7LVy59aj9FHsb3N";
*/

/* Authnet / Sandbox 
  export const AUTHNET_SCRIPT = "https://jstest.authorize.net/v1/Accept.js";
  export const AUTHNET_LOGINID = "34fhtk8Be8C7";
  export const AUTHNET_PUBKEY =
    "44S79RZmJ3jf7teah5u8wp95WCcRHX2rJ3S6a48Hx3t6w6jarbaRQFnGEaLVcLd6";
*/

const isBrowser = () => typeof window !== "undefined";

export const getObjFromLS = (key) =>
	isBrowser() && window.localStorage.getItem(key)
		? JSON.parse(window.localStorage.getItem(key))
		: undefined;

export const setObjToLS = (key, obj) => {
	if (isBrowser() && obj) {
		window.localStorage.setItem(key, JSON.stringify(obj));
	} else {
		window.localStorage.removeItem(key);
	}
	return true;
};

export const STATES = [
	{ value: "", name: "--" },
	{ value: "AL", name: "Alabama" },
	{ value: "AK", name: "Alaska" },
	{ value: "AZ", name: "Arizona" },
	{ value: "AR", name: "Arkansas" },
	{ value: "CA", name: "California" },
	{ value: "CO", name: "Colorado" },
	{ value: "CT", name: "Connecticut" },
	{ value: "DE", name: "Delaware" },
	{ value: "DC", name: "District of Columbia" },
	{ value: "FL", name: "Florida" },
	{ value: "GA", name: "Georgia" },
	{ value: "HI", name: "Hawaii" },
	{ value: "ID", name: "Idaho" },
	{ value: "IL", name: "Illinois" },
	{ value: "IN", name: "Indiana" },
	{ value: "IA", name: "Iowa" },
	{ value: "KS", name: "Kansas" },
	{ value: "KY", name: "Kentucky" },
	{ value: "LA", name: "Louisiana" },
	{ value: "ME", name: "Maine" },
	{ value: "MD", name: "Maryland" },
	{ value: "MA", name: "Massachusetts" },
	{ value: "MI", name: "Michigan" },
	{ value: "MN", name: "Minnesota" },
	{ value: "MS", name: "Mississippi" },
	{ value: "MO", name: "Missouri" },
	{ value: "MT", name: "Montana" },
	{ value: "NE", name: "Nebraska" },
	{ value: "NV", name: "Nevada" },
	{ value: "NH", name: "New Hampshire" },
	{ value: "NJ", name: "New Jersey" },
	{ value: "NM", name: "New Mexico" },
	{ value: "NY", name: "New York" },
	{ value: "NC", name: "North Carolina" },
	{ value: "ND", name: "North Dakota" },
	{ value: "OH", name: "Ohio" },
	{ value: "OK", name: "Oklahoma" },
	{ value: "OR", name: "Oregon" },
	{ value: "PA", name: "Pennsylvania" },
	{ value: "PR", name: "Puerto Rico" },
	{ value: "RI", name: "Rhode Island" },
	{ value: "SC", name: "South Carolina" },
	{ value: "SD", name: "South Dakota" },
	{ value: "TN", name: "Tennessee" },
	{ value: "TX", name: "Texas" },
	{ value: "UT", name: "Utah" },
	{ value: "VT", name: "Vermont" },
	{ value: "VI", name: "Virgin Islands" },
	{ value: "VA", name: "Virginia" },
	{ value: "WA", name: "Washington" },
	{ value: "WV", name: "West Virginia" },
	{ value: "WI", name: "Wisconsin" },
	{ value: "WY", name: "Wyoming" },
];

export const COUNTIES = [
	"Alachua",
	"Baker",
	"Bay",
	"Bradford",
	"Brevard",
	"Broward",
	"Calhoun",
	"Charlotte",
	"Citrus",
	"Clay",
	"Collier",
	"Columbia",
	"DeSoto",
	"Dixie",
	"Duval",
	"Escambia",
	"Flagler",
	"Franklin",
	"Gadsden",
	"Gilchrist",
	"Glades",
	"Gulf",
	"Hamilton",
	"Hardee",
	"Hendry",
	"Hernando",
	"Highlands",
	"Hillsborough",
	"Holmes",
	"Indian River",
	"Jackson",
	"Jefferson",
	"Lafayette",
	"Lake",
	"Lee",
	"Leon",
	"Levy",
	"Liberty",
	"Madison",
	"Manatee",
	"Marion",
	"Martin",
	"Miami-Dade",
	"Monroe",
	"Nassau",
	"Okaloosa",
	"Okeechobee",
	"Orange",
	"Osceola",
	"Palm Beach",
	"Pasco",
	"Pinellas",
	"Polk",
	"Putnam",
	"Santa Rosa",
	"Sarasota",
	"Seminole",
	"St. Johns",
	"St. Lucie",
	"Sumter",
	"Suwannee",
	"Taylor",
	"Union",
	"Volusia",
	"Wakulla",
	"Walton",
	"Washington",
];

export const GUESS_AMTS = [
	{ value: 20, name: "$20 (1 Guess)", guesses: 1, display: true },
	{ value: 40, name: "$40 (2 Guesses)", guesses: 2, display: true },
	{ value: 50, name: "$50 (3 Guesses)", guesses: 3, display: true },
	{ value: 70, name: "$70 (4 Guesses)", guesses: 4, display: false },
	{ value: 90, name: "$90 (5 Guesses)", guesses: 5, display: false },
	{ value: 100, name: "$100 (6 Guesses)", guesses: 6, display: true },
	{ value: 120, name: "$120 (7 Guesses)", guesses: 7, display: false },
	{ value: 140, name: "$140 (8 Guesses)", guesses: 8, display: false },
	{ value: 150, name: "$150 (9 Guesses)", guesses: 9, display: false },
	{ value: 170, name: "$170 (10 Guesses)", guesses: 10, display: false },
	{ value: 190, name: "$190 (11 Guesses)", guesses: 11, display: false },
	{ value: 200, name: "$200 (12 Guesses)", guesses: 12, display: false },
	{ value: 220, name: "$220 (13 Guesses)", guesses: 13, display: false },
	{ value: 240, name: "$240 (14 Guesses)", guesses: 14, display: false },
	{ value: 250, name: "$250 (15 Guesses)", guesses: 15, display: true },
	{ value: 270, name: "$270 (16 Guesses)", guesses: 16, display: false },
	{ value: 290, name: "$290 (17 Guesses)", guesses: 17, display: false },
	{ value: 300, name: "$300 (18 Guesses)", guesses: 18, display: false },
	{ value: 320, name: "$320 (19 Guesses)", guesses: 19, display: false },
	{ value: 340, name: "$340 (20 Guesses)", guesses: 20, display: false },
	{ value: 350, name: "$350 (21 Guesses)", guesses: 21, display: false },
	{ value: 370, name: "$370 (22 Guesses)", guesses: 22, display: false },
	{ value: 390, name: "$390 (23 Guesses)", guesses: 23, display: false },
	{ value: 400, name: "$400 (24 Guesses)", guesses: 24, display: false },
	{ value: 420, name: "$420 (25 Guesses)", guesses: 25, display: false },
	{ value: 440, name: "$440 (26 Guesses)", guesses: 26, display: false },
	{ value: 450, name: "$450 (27 Guesses)", guesses: 27, display: false },
	{ value: 470, name: "$470 (28 Guesses)", guesses: 28, display: false },
	{ value: 490, name: "$490 (29 Guesses)", guesses: 29, display: false },
	{ value: 500, name: "$500 (30 Guesses)", guesses: 30, display: true },
	{ value: "other", name: "Other (up to 30)", guesses: 0, display: true },
];

export const PREV_GUESSES = [
	{ yr: "2007", birdies: "1,116" },
	{ yr: "2008", birdies: "1,144" },
	{ yr: "2009", birdies: "1,180" },
	{ yr: "2010", birdies: "1,181" },
	{ yr: "2011", birdies: "1,041" },
	{ yr: "2012", birdies: "1,279" },
	{ yr: "2013", birdies: "1,244" },
	{ yr: "2014", birdies: "1,363" },
	{ yr: "2015", birdies: "1,168" },
	{ yr: "2016", birdies: "1,246" },
	{ yr: "2017", birdies: "1,361" },
	{ yr: "2018", birdies: "1,090" },
	{ yr: "2019", birdies: "1,320" },
	{ yr: "2020", birdies: "1,172" },
	{ yr: "2021", birdies: "1,345" },
	{ yr: "2022", birdies: "1,262" },
	{ yr: "2023", birdies: "1,419" },
];

export const RACES = [
	{ value: "", name: "--" },
	{
		value: "American Indian or Alaska Native",
		name: "American Indian or Alaska Native",
	},
	{ value: "White", name: "White" },
	{ value: "Asian", name: "Asian" },
	{ value: "Black or African American", name: "Black or African American" },
	{ value: "Hispanic or Latino", name: "Hispanic or Latino" },
	{
		value: "Native Hawaiian or Other Pacific Islander",
		name: "Native Hawaiian or Other Pacific Islander",
	},
	{ value: "Not Specified", name: "Not Specified" },
	{ value: "Two or More Races", name: "Two or More Races" },
	{ value: "I choose not to answer", name: "I choose not to answer" },
];

export const RACESAMBASS = [
	{ value: "", name: "--" },
	{ value: "Asian", name: "Asian" },
	{ value: "Black or African American", name: "Black or African American" },
	{ value: "Hispanic or Latino", name: "Hispanic or Latino" },
	{
		value: "Native Hawaiian or Other Pacific Islander",
		name: "Native Hawaiian or Other Pacific Islander",
	},
	{ value: "White", name: "White" },
	{ value: "Not Specified", name: "Not Specified" },
	{ value: "Two or More Races", name: "Two or More Races" },
	{ value: "I choose not to answer", name: "I choose not to answer" },
];

export const SHIRTS = [
	{ value: "", name: "--" },
	{ value: "Men’s SM", name: "Men’s SM" },
	{ value: "Men’s MED", name: "Men’s MED" },
	{ value: "Men’s LG", name: "Men’s LG" },
	{ value: "Men’s XL", name: "Men’s XL" },
	{ value: "Men’s XXL", name: "Men’s XXL" },
	{ value: "Ladies XS", name: "Ladies XS" },
	{ value: "Ladies SM", name: "Ladies SM" },
	{ value: "Ladies MED", name: "Ladies MED" },
	{ value: "Ladies LG", name: "Ladies LG" },
	{ value: "Ladies XL", name: "Ladies XL" },
];

export const SIZESA = [
	{ value: "", name: "--" },
	{ value: "XXS", name: "Extra Extra Small" },
	{ value: "XS", name: "Extra Small" },
	{ value: "S", name: "Small" },
	{ value: "M", name: "Medium" },
	{ value: "L", name: "Large" },
	{ value: "XL", name: "Extra Large" },
];

export const SIZESB = [
	{ value: "", name: "--" },
	{ value: "00", name: "00" },
	{ value: "0", name: "0" },
	{ value: "2", name: "2" },
	{ value: "4", name: "4" },
	{ value: "6", name: "6" },
	{ value: "8", name: "8" },
	{ value: "10", name: "10" },
	{ value: "12", name: "12" },
	{ value: "14", name: "14" },
	{ value: "16", name: "16" },
];

export const WLFTICKETS = {
	individual: {
		amt: 15000,
		name: "Individual Ticket",
		seats: 1,
	},
	table: {
		amt: 150000,
		name: "VIP Table Sponsor",
		seats: 10,
	},
};

export function fmtCurrency(int) {
	const amt = int;
	return `${amt.toLocaleString("en-US", {
		style: "currency",
		currency: "USD",
	})}`;
}

export function createMarkup(html) {
	return { __html: html };
}

export const isCardType = (str) => {
	if (str) {
		str = str.replace(/\s/g, "").replace(/-/g, "");
		const visa = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
		const mast = /^(?:5[1-5][0-9]{14})$/;
		const amex = /^(?:3[47][0-9]{13})$/;
		const disc = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
		if (str.match(visa)) {
			return "visa";
		} else if (str.match(mast)) {
			return "mastercard";
		} else if (str.match(amex)) {
			return "amex";
		} else if (str.match(disc)) {
			return "discover";
		} else {
			return "no";
		}
	} else {
		return "no";
	}
};
