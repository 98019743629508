import React from "react";
import "./styles.css";

function isFieldError(array, val) {
	return array.includes(val);
}

const Textarea = ({
	label,
	note,
	req,
	name,
	value,
	update,
	errors,
	h3 = false,
}) => {
	return (
		<div
			className={
				name === "mission" || name === "text" || name === "description"
					? "textarea larger"
					: "textarea"
			}
		>
			<label htmlFor={name} className={!label ? "hidden" : h3 ? "h3" : ""}>
				{`${label}`} {req ? <span className="error">*</span> : null}
			</label>
			<textarea
				name={name}
				id={name}
				value={value || ""}
				onChange={(e) => update(name, e.target.value)}
				autoComplete="off"
				className={isFieldError(errors, name) ? "error-border" : ""}
			/>
			<small className={note ? "" : "hidden"}>{note}</small>
		</div>
	);
};

export default Textarea;
