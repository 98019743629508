import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

import Enter from "./Enter";
import Returning from "./Returning";
import Home from "./Home/";
import Quest1 from "./Quest1/";
import Quest2 from "./Quest2/";
import Quest3 from "./Quest3/";
import Quest4 from "./Quest4/";
import Upload from "./Upload/";
import SubmitToCHC from "./SubmitToCHC";
import Signout from "./Signout";

import "./styles.css";

function Grants({ cares }) {
	return (
		<Router basename="/grant-application">
			<Routes>
				<Route path="/enter" element={<Enter cares={cares} />} />
				<Route path="/returning/:code" element={<Returning />} />
				<Route path="/" element={<Home cares={cares} />} />
				<Route path="/quest1" element={<Quest1 cares={cares} />} />
				<Route path="/quest2" element={<Quest2 cares={cares} />} />
				<Route path="/quest3" element={<Quest3 cares={cares} />} />
				<Route path="/quest4" element={<Quest4 cares={cares} />} />
				<Route path="/upload/:step" element={<Upload cares={cares} />} />
				<Route path="/submit" element={<SubmitToCHC cares={cares} />} />
				<Route path="/signout" element={<Signout cares={cares} />} />
			</Routes>
		</Router>
	);
}

export default Grants;
