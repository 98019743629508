import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
	API_URL_GRANTS as API_URL,
	getObjFromLS,
} from "../../../utils/helpers";

import Alert from "../../../components/Alert";
import Notice from "../../../components/Notice";
import Select from "../../../components/Select";
import Textarea from "../../../components/Textarea";
import Msg from "../../../components/Msg";
import Submit from "../../../components/Submit";

import Back from "../Back";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const Quest3 = ({ cares }) => {
	const [user, setUser] = useState({});
	const [names, setNames] = useState({});
	const [errors, setErrors] = useState([]);
	const [msg, setMsg] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const formElement = useRef();
	const navigate = useNavigate();
	const initRef = useRef(false);

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;

		if (getObjFromLS("grants")) {
			const obj = getObjFromLS("grants");

			init(obj);
		} else {
			navigate("/enter");
		}
	});

	const init = async ({ token, id, email }) => {
		const url = new URL(`${API_URL}/`);
		const params = {
			admin: 1,
			f: "startedInit",
			token,
			x: uuidv4(),
		};

		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url.href, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setUser({
					token,
					id,
					email,
				});

				const section = json.sections.find(
					(section) => section.key === "quest3"
				);

				setNames((names) => ({
					...names,
					...json.data.quest3,
					descr: section.descr,
				}));

				setAlert({});
			} else {
				setAlert({
					type: "error",
					text: "An error occurred accessing your application.",
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error occurred accessing your application.",
			});
		}
	};

	const handleUpdate = (name, value) => {
		setNames((names) => ({ ...names, [name]: value ? value : "" }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?admin=1&f=quest3`);
		let formData = new FormData(formElement.current);
		formData.append("token", user.token);

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				navigate("/", {
					state: {
						type: "success",
						text: json.text,
					},
				});
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			<Back />
			<h2>{names.descr ? names.descr : ""}</h2>
			<p style={{ marginBottom: "15px" }}>
				Entry is required in <strong>ALL</strong>{" "}
				<span style={{ color: "var(--error)" }}>*</span> fields to complete this
				section. We have designed the application as such that you can return
				later to update your details, but please note that an entry (you may
				indicate “not applicable”) is required in each{" "}
				<span style={{ color: "var(--error)" }}>*</span> field in order to save
				your progress.
			</p>

			<Notice
				data={{
					text: "Reminder if copying/pasting from a Word document, please save or export from Word as plain text before pasting your content into the answer fields.",
				}}
			/>

			<div className="grants form-container">
				{alert.type ? (
					<Alert data={alert} />
				) : (
					<form
						method="post"
						action="/"
						onSubmit={(e) => handleSubmit(e)}
						ref={formElement}
					>
						<h3>
							Was your organization awarded a grant from the 2024 Children’s
							Healthcare Charity? If yes, please provide the following.
						</h3>

						<div className="two" style={{ marginTop: "0" }}>
							<div>
								<Select
									label="Select"
									req={true}
									name="awarded"
									value={names.awarded}
									data={[
										{ value: "", name: "--" },
										{ value: "Yes", name: "Yes" },
										{ value: "No", name: "No" },
									]}
									update={handleUpdate}
									errors={errors}
								/>
							</div>
							<div className="empty" />
						</div>

						{names.awarded === "Yes" && (
							<Textarea
								label="Detailed summary of the results from the CHC grant funding and how the funded grant directly affected the success of the project?"
								req={true}
								name="summary"
								value={names.summary}
								update={handleUpdate}
								errors={errors}
								note="Limited to 200 words"
								h3={true}
							/>
						)}

						{names.awarded === "Yes" && (
							<Textarea
								label="What, if any unanticipated results, challenges, obstacles, or unforeseen successes did the charity experience during the implantation of the funded grant."
								req={true}
								name="unanticipated"
								value={names.unanticipated}
								update={handleUpdate}
								errors={errors}
								note="Limited to 200 words"
								h3={true}
							/>
						)}

						{names.awarded === "Yes" && (
							<Textarea
								label="Testimonial of the use of the grant, which could be used on social media showing the support from CHC to your nonprofit."
								req={false}
								name="testimonial"
								value={names.testimonial}
								update={handleUpdate}
								errors={errors}
								note="Limited to 200 words"
								h3={true}
							/>
						)}

						<div className="msg-submit">
							{msg.type && <Msg data={msg} />}

							<div
								className={
									msg.type === "working" ? "hidden" : "submit-container"
								}
							>
								<Submit
									name="Submit Answers"
									icon={faChevronCircleRight}
									cares={cares}
								/>
							</div>
						</div>
					</form>
				)}
			</div>
		</>
	);
};

export default Quest3;
