import Input from "../../../components/Input";
import Select from "../../../components/Select";

const Fields2 = ({ names, handleUpdate, errors, STATES }) => {
	return (
		<>
			<div className="two">
				<div>
					<Input
						type="text"
						label="Street Address"
						req={true}
						name="address"
						value={names.address}
						update={handleUpdate}
						errors={errors}
					/>
				</div>

				<div className="empty" />
			</div>

			<div className="three">
				<div>
					<Input
						type="text"
						label="City"
						req={true}
						name="city"
						value={names.city}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
				<div className="small">
					<Select
						label="State"
						req={true}
						name="st"
						value={names.st}
						data={STATES}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
				<div className="small">
					<Input
						type="text"
						label="Zip"
						req={true}
						name="zip"
						value={names.zip}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
			</div>

			<div className="two">
				<div>
					<Input
						type="text"
						label="Phone Number"
						req={true}
						name="phone"
						value={names.phone || ""}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
				<div>
					<Input
						type="text"
						label="Web Site"
						req={false}
						name="web"
						value={names.web || ""}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
			</div>
		</>
	);
};

export default Fields2;
