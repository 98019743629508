import Input from "../../../components/Input";

const Fields1 = ({ names, handleUpdate, errors }) => {
	return (
		<>
			<h3>
				What percentage of your organizations overall budget is funded by state
				or federal funds through grants or programs it participated in?
			</h3>

			<div className="two" style={{ marginTop: "0" }}>
				<div>
					<Input
						type="text"
						label="Enter a single %"
						req={true}
						name="percentageFunded"
						value={names.percentageFunded || ""}
						update={handleUpdate}
						errors={errors}
						note=""
					/>
				</div>
				<div className="empty" />
			</div>

			<h3 style={{ marginTop: "20px" }}>
				What percentage of your organizations overall budget must be fundraised
				at the local level?
			</h3>

			<div className="two" style={{ marginTop: "0" }}>
				<div>
					<Input
						type="text"
						label="Enter a single %"
						req={true}
						name="percentageFundraised"
						value={names.percentageFundraised || ""}
						update={handleUpdate}
						errors={errors}
						note=""
					/>
				</div>
				<div className="empty" />
			</div>
		</>
	);
};

export default Fields1;
