import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
	API_URL_GRANTS as API_URL,
	getObjFromLS,
} from "../../../utils/helpers";

import Alert from "../../../components/Alert";
import Select from "../../../components/Select";
import InputFile from "../../../components/InputFile";
import Msg from "../../../components/Msg";
import Submit from "../../../components/Submit";

import Back from "../Back";

import {
	faChevronCircleRight,
	faFilePdf,
	faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Upload = ({ cares }) => {
	const [user, setUser] = useState({});
	const [names, setNames] = useState({});
	const [errors, setErrors] = useState([]);
	const [msg, setMsg] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const formElement = useRef();
	const navigate = useNavigate();
	const { step } = useParams();
	const initRef = useRef(false);

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;

		if (getObjFromLS("grants")) {
			const obj = getObjFromLS("grants");

			init(obj);
		} else {
			navigate("/enter");
		}
	});

	const init = async ({ token, id, email }) => {
		const url = new URL(`${API_URL}/`);
		const params = {
			admin: 1,
			f: "startedInit",
			token,
			x: uuidv4(),
		};

		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url.href, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setUser({
					token,
					id,
					email,
				});

				const section = json.sections.find((section) => section.key === step);
				const split = section.descr.split(": ");

				setNames((names) => ({
					...names,
					...json.data[step],
					descr: `${split[0]}: Upload PDF Document`,
					h3: split[1],
				}));

				setAlert({});
			} else {
				setAlert({
					type: "error",
					text: "An error occurred accessing your application.",
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error occurred accessing your application.",
			});
		}
	};

	const handleUpdate = (name, value) => {
		setNames((names) => ({ ...names, [name]: value ? value : "" }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?admin=1&f=upload`);
		let formData = new FormData(formElement.current);
		formData.append("step", step);
		formData.append("type", names.type);
		formData.append("token", user.token);

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				navigate("/", {
					state: {
						type: "success",
						text: json.text,
					},
				});
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	const handleDelete = async (fileName) => {
		const url = new URL(`${API_URL}/?admin=1&f=uploadDelete`);
		let formData = new FormData();
		formData.append("step", step);
		formData.append("fileName", fileName);
		formData.append("token", user.token);

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				navigate("/", {
					state: {
						type: "success",
						text: json.text,
					},
				});
			}
		} catch (error) {}
	};

	return (
		<>
			<Back />
			<h2>{names.descr ? names.descr : ""}</h2>
			{names.type === "uploadRequired" ? (
				<p>Please upload the required document by selecting a file below.</p>
			) : (
				<p>
					Please upload the appropriate support document(s) using the form
					below. If this section isn’t applicable to your organization, please
					indicate as such to mark the section as completed.
				</p>
			)}

			<div className="grants form-container">
				{alert.type ? (
					<Alert data={alert} />
				) : (
					<>
						{names.files.length > 0 && (
							<div
								className="scholarship"
								style={{ marginTop: "0", marginBottom: "25px" }}
							>
								<table className="order">
									<thead>
										<tr>
											<th className="left">Document(s) Uploaded</th>
											<th style={{ width: "15%" }}>Delete</th>
										</tr>
									</thead>

									<tbody className="grant-uploads">
										{names.files.map((item) => {
											return (
												<tr key={item}>
													<td className="left">
														<a
															href={`https://childrenshealthcarecharity.org/wp-content/uploads/grants/${user.id}/${item}`}
															target="_blank"
															rel="noreferrer"
														>
															<FontAwesomeIcon icon={faFilePdf} size="lg" />
															<span>{item}</span>
														</a>
													</td>
													<td>
														<button
															type="button"
															onClick={() => handleDelete(item)}
														>
															<FontAwesomeIcon
																icon={faTrashAlt}
																size="lg"
																style={{ color: "var(--error)" }}
															/>
														</button>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						)}

						<form
							method="post"
							action="/"
							onSubmit={(e) => handleSubmit(e)}
							ref={formElement}
						>
							<h3>{names.h3}</h3>

							{names.type === "upload" && (
								<div className="two" style={{ marginBottom: "25px" }}>
									<div>
										<Select
											label="Document(s) to upload for this section?"
											req={true}
											name="required"
											value={names.required}
											data={[
												{ value: "", name: "--" },
												{ value: "Yes", name: "Yes" },
												{ value: "No", name: "No" },
											]}
											update={handleUpdate}
											errors={errors}
										/>
									</div>
									<div className="empty" />
								</div>
							)}

							{names.required !== "No" && (
								<div className="one">
									<InputFile
										label=""
										req={true}
										name="file"
										value=""
										update={handleUpdate}
										errors={errors}
									/>
								</div>
							)}

							{names.required !== "No" && (
								<p>Please upload your document in PDF format.</p>
							)}

							<div className="msg-submit">
								{msg.type && <Msg data={msg} />}

								<div
									className={
										msg.type === "working" ? "hidden" : "submit-container"
									}
								>
									<Submit
										name="Submit Upload"
										icon={faChevronCircleRight}
										cares={cares}
									/>
								</div>
							</div>
						</form>
					</>
				)}
			</div>
		</>
	);
};

export default Upload;
