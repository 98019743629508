import React from "react";
import "./styles.css";

function isFieldError(array, val) {
	return array.includes(val);
}

const InputFile = ({ label, note = "", req, name, value, update, errors }) => {
	return (
		<div className="input" name={name}>
			<label htmlFor={name} className={!label ? "hidden" : ""}>
				{`${label}`} {req ? <span className="error">*</span> : null}
			</label>
			<input
				type="file"
				name={name}
				id={name}
				defaultValue={value || ""}
				onChange={(e) => update(name, e.target.files[0])}
				autoComplete="off"
				className={isFieldError(errors, name) ? "error-border" : ""}
				style={{ cursor: "pointer" }}
			/>
			<small className={note ? "" : "hidden"}>{note}</small>
		</div>
	);
};

export default InputFile;
