import Input from "../../../components/Input";

const Fields3 = ({ names, handleUpdate, errors }) => {
	return (
		<>
			<h3 style={{ marginBottom: "10px" }}>
				From your recent 2023 990 tax return, please list:
			</h3>

			<div className="two" style={{ marginTop: "0" }}>
				<div>
					<Input
						type="text"
						label="Total Revenue (line 12)"
						req={true}
						name="revenue"
						value={names.revenue || ""}
						update={handleUpdate}
						errors={errors}
						note="Enter a single $ amount"
					/>
				</div>
				<div>
					<Input
						type="text"
						label="Total Expenses (line 18)"
						req={true}
						name="expenses"
						value={names.expenses || ""}
						update={handleUpdate}
						errors={errors}
						note="Enter a single $ amount"
					/>
				</div>
			</div>

			<div className="two" style={{ marginTop: "15px" }}>
				<div>
					<Input
						type="text"
						label="Profit (line 19)"
						req={true}
						name="profit"
						value={names.profit || ""}
						update={handleUpdate}
						errors={errors}
						note="Enter a single $ amount"
					/>
				</div>
				<div>
					<Input
						type="text"
						label="Total Assets (line 20)"
						req={true}
						name="assets"
						value={names.assets || ""}
						update={handleUpdate}
						errors={errors}
						note="Enter a single $ amount"
					/>
				</div>
			</div>

			<div className="two" style={{ marginTop: "15px" }}>
				<div>
					<Input
						type="text"
						label="Total Liabilities (line 21)"
						req={true}
						name="liabilities"
						value={names.liabilities || ""}
						update={handleUpdate}
						errors={errors}
						note="Enter a single $ amount"
					/>
				</div>
				<div>
					<Input
						type="text"
						label="Net Assets (line 22)"
						req={true}
						name="net"
						value={names.net || ""}
						update={handleUpdate}
						errors={errors}
						note="Enter a single $ amount"
					/>
				</div>
			</div>
		</>
	);
};

export default Fields3;
