import Select from "../../../components/Select";
import Input from "../../../components/Input";

const Fields3 = ({ names, handleUpdate, errors }) => {
	return (
		<>
			<h3>
				Does the proposed program serve at least 51% of an underserved
				population?
			</h3>

			<div className="two" style={{ marginTop: "0" }}>
				<div>
					<Select
						label="Select"
						req={true}
						name="underserved"
						value={names.underserved}
						data={[
							{ value: "", name: "--" },
							{ value: "Yes", name: "Yes" },
							{ value: "No", name: "No" },
						]}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
				<div className="empty" />
			</div>

			<h3 style={{ marginTop: "20px" }}>
				Are the percentage of people served by the proposed program at least 51%
				Black or African American citizens?
			</h3>

			<div className="two" style={{ marginTop: "0" }}>
				<div>
					<Select
						label="Select"
						req={true}
						name="black"
						value={names.black}
						data={[
							{ value: "", name: "--" },
							{ value: "Yes", name: "Yes" },
							{ value: "No", name: "No" },
						]}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
				<div className="empty" />
			</div>

			{names.black === "No" && (
				<h3 style={{ marginTop: "20px" }}>
					If no, what group(s) comprise at least 51% of the people served by the
					program?
				</h3>
			)}

			{names.black === "No" && (
				<div className="two" style={{ marginTop: "0" }}>
					<div>
						<Select
							label="Select"
							req={true}
							name="selectGroup"
							value={""}
							data={[
								{ value: "", name: "--" },
								{
									value: "Indian or Alaskan American",
									name: "Indian or Alaskan American",
								},
								{ value: "Asian", name: "Asian" },
								{
									value: "Black / African American",
									name: "Black / African American",
								},
								{
									value: "Hispanic / Latino / Spanish",
									name: "Hispanic / Latino / Spanish",
								},
								{
									value: "Native Hawaiian / Pacific Islander",
									name: "Native Hawaiian / Pacific Islander",
								},
								{
									value: "White",
									name: "White",
								},
							]}
							update={handleUpdate}
							errors={errors}
						/>
					</div>

					<Input
						type="text"
						label="Selected Groups"
						req={true}
						name="groups"
						value={names.groups || ""}
						update={handleUpdate}
						errors={errors}
						note=""
					/>
				</div>
			)}
		</>
	);
};

export default Fields3;
