import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
	API_URL_GRANTS as API_URL,
	COUNTIES,
	getObjFromLS,
} from "../../../utils/helpers";

import Alert from "../../../components/Alert";
import Notice from "../../../components/Notice";
import Msg from "../../../components/Msg";
import Submit from "../../../components/Submit";

import Back from "../Back";

import Fields1 from "./Fields1";
import Fields2 from "./Fields2";
import Fields3 from "./Fields3";
import Fields4 from "./Fields4";
import Fields5 from "./Fields5";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const Quest2 = ({ cares }) => {
	const [user, setUser] = useState({});
	const [names, setNames] = useState({});
	const [errors, setErrors] = useState([]);
	const [msg, setMsg] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const formElement = useRef();
	const navigate = useNavigate();
	const initRef = useRef(false);

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;

		if (getObjFromLS("grants")) {
			const obj = getObjFromLS("grants");

			init(obj);
		} else {
			navigate("/enter");
		}
	});

	const init = async ({ token, id, email }) => {
		const url = new URL(`${API_URL}/`);
		const params = {
			admin: 1,
			f: "startedInit",
			token,
			x: uuidv4(),
		};

		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url.href, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setUser({
					token,
					id,
					email,
				});

				const section = json.sections.find(
					(section) => section.key === "quest2"
				);

				setNames((names) => ({
					...names,
					...json.data.quest2,
					descr: section.descr,
				}));

				setAlert({});
			} else {
				setAlert({
					type: "error",
					text: "An error occurred accessing your application.",
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error occurred accessing your application.",
			});
		}
	};

	const handleUpdate = (name, value) => {
		if (name === "selectGroup" && value) {
			const groups = names.groups || "";
			const newGroups = groups ? `${groups}, ${value}` : `${value}`;
			setNames((names) => ({ ...names, groups: newGroups }));
		} else if (name === "selectRegion" && value) {
			const regions = names.regions || "";
			const newRegions = regions ? `${regions}, ${value}` : `${value}`;
			setNames((names) => ({ ...names, regions: newRegions }));
		} else {
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?admin=1&f=quest2`);
		let formData = new FormData(formElement.current);
		formData.append("groups", names.groups || "");
		formData.append("regions", names.regions || "");
		formData.append("token", user.token);

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				navigate("/", {
					state: {
						type: "success",
						text: json.text,
					},
				});
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			<Back />
			<h2>{names.descr ? names.descr : ""}</h2>
			<p style={{ marginBottom: "15px" }}>
				Entry is required in <strong>ALL</strong>{" "}
				<span style={{ color: "var(--error)" }}>*</span> fields to complete this
				section. We have designed the application as such that you can return
				later to update your details, but please note that an entry (you may
				indicate “not applicable”) is required in each{" "}
				<span style={{ color: "var(--error)" }}>*</span> field in order to save
				your progress.
			</p>

			<Notice
				data={{
					text: "Reminder if copying/pasting from a Word document, please save or export from Word as plain text before pasting your content into the answer fields.",
				}}
			/>

			<div className="grants form-container">
				{alert.type ? (
					<Alert data={alert} />
				) : (
					<form
						method="post"
						action="/"
						onSubmit={(e) => handleSubmit(e)}
						ref={formElement}
					>
						<Fields1
							names={{ summary: names.summary, description: names.description }}
							handleUpdate={handleUpdate}
							errors={errors}
						/>

						<p
							className="line"
							style={{ marginTop: "10px", marginBottom: "15px" }}
						/>

						<Fields2
							names={{
								amount: names.amount,
								children: names.children,
								cost: names.cost,
								timetable: names.timetable,
							}}
							handleUpdate={handleUpdate}
							errors={errors}
						/>

						<p
							className="line"
							style={{ marginTop: "10px", marginBottom: "15px" }}
						/>

						<Fields3
							names={{
								underserved: names.underserved,
								black: names.black,
								groups: names.groups,
							}}
							handleUpdate={handleUpdate}
							errors={errors}
						/>

						<p
							className="line"
							style={{ marginTop: "10px", marginBottom: "15px" }}
						/>

						<Fields4
							names={{
								regions: names.regions,
								notFunded: names.notFunded,
								mission: names.mission,
							}}
							handleUpdate={handleUpdate}
							errors={errors}
							COUNTIES={COUNTIES}
						/>

						<p
							className="line"
							style={{ marginTop: "10px", marginBottom: "15px" }}
						/>

						<Fields5
							names={{
								areas: names.areas,
							}}
						/>

						<div className="msg-submit">
							{msg.type && <Msg data={msg} />}

							<div
								className={
									msg.type === "working" ? "hidden" : "submit-container"
								}
							>
								<Submit
									name="Submit Answers"
									icon={faChevronCircleRight}
									cares={cares}
								/>
							</div>
						</div>
					</form>
				)}
			</div>
		</>
	);
};

export default Quest2;
