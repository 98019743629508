import Input from "../../../components/Input";

const Fields3 = ({ names, handleUpdate, errors }) => {
	return (
		<>
			<div className="two">
				<div>
					<Input
						type="text"
						label="Name of Executive Director"
						req={true}
						name="execDirName"
						value={names.execDirName}
						update={handleUpdate}
						errors={errors}
					/>
				</div>

				<div className="empty" />
			</div>

			<div className="two">
				<div>
					<Input
						type="text"
						label="Executive Director Email"
						req={true}
						name="execDirEmail"
						value={names.execDirEmail}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
				<div>
					<Input
						type="text"
						label="Executive Director Phone"
						req={true}
						name="execDirPhone"
						value={names.execDirPhone}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
			</div>
		</>
	);
};

export default Fields3;
