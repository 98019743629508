import Input from "../../../components/Input";

const Fields1 = ({ names, handleUpdate, errors }) => {
	return (
		<>
			<div className="two">
				<div>
					<Input
						type="text"
						label="Organization Name"
						req={true}
						name="orgName"
						value={names.orgName || ""}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
				<div>
					<Input
						type="text"
						label="EIN Number"
						req={true}
						name="ein"
						value={names.ein || ""}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
			</div>
		</>
	);
};

export default Fields1;
