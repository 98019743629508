import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { setObjToLS } from "../../utils/helpers";

import Alert from "../../components/Alert";

const Signout = () => {
	const navigate = useNavigate();
	const initRef = useRef(false);

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;

		init();
	});

	const init = async () => {
		setObjToLS("grants", null);
		navigate("/enter");
	};

	return <Alert data={{ type: "working", text: "" }} />;
};

export default Signout;
