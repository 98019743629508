import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft } from "@fortawesome/pro-light-svg-icons";

import "./styles.css";

const Back = () => {
	return (
		<Link to="/" className="grants back">
			<FontAwesomeIcon icon={faChevronCircleLeft} size="lg" />
			<span>Back to Application Home</span>
		</Link>
	);
};

export default Back;
