import { Link } from "react-router-dom";

import Notice from "../../../components/Notice";

import Table from "./Table";

const ReadyToSubmit = ({ sections, status }) => {
	return (
		<>
			<Notice
				data={{
					type: "notice",
					text: "All sections have been marked as completed and your application is ready to be submitted.",
				}}
			/>

			<p style={{ paddingTop: "20px", paddingBottom: "15px" }}>
				<Link
					to="/submit"
					style={{
						fontSize: "1.25rem",
						textAlign: "center",
						textTransform: "uppercase",
						border: "1px solid var(--blue)",
						borderRadius: "4px",
						padding: "15px 15px 12px 15px",
						backgroundColor: "var(--blue)",
						color: "#fff",
						fontWeight: "500",
						letterSpacing: "0.5px",
					}}
				>
					Submit application to CHC
				</Link>
			</p>

			<p
				className="line"
				style={{
					marginBottom: "15px",
				}}
			>
				Note: you can review your completed application on the next page before
				finalizing your submission.
			</p>

			<Table sections={sections} status={status} />
		</>
	);
};

export default ReadyToSubmit;
