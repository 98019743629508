import Notice from "../../../components/Notice";

const Submitted = () => {
	return (
		<>
			<Notice
				data={{
					type: "success",
					text: "Thank you for submitting your Children's Healthcare Charity grant application.",
				}}
			/>
		</>
	);
};

export default Submitted;
