import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
	API_URL_GRANTS as API_URL,
	getObjFromLS,
	setObjToLS,
} from "../../../utils/helpers";

import Alert from "../../../components/Alert";
import Notice from "../../../components/Notice";

import Submitted from "./Submitted";
import ReadyToSubmit from "./ReadyToSubmit";
import Table from "./Table";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-light-svg-icons";

const Home = ({ cares }) => {
	const [user, setUser] = useState({});
	const [status, setStatus] = useState({});
	const [sections, setSections] = useState([]);
	const [notice, setNotice] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const location = useLocation();
	const navigate = useNavigate();
	const initRef = useRef(false);

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;

		if (getObjFromLS("grants")) {
			const obj = getObjFromLS("grants");

			init(obj);
		} else {
			navigate("/enter");
		}
	});

	const init = async ({ token, id, email }) => {
		const url = new URL(`${API_URL}/`);
		const params = {
			admin: 1,
			f: "startedInit",
			token,
			x: uuidv4(),
		};

		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url.href, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setUser({
					token,
					id,
					email,
				});

				setStatus(json.status);
				setSections(json.sections);

				setAlert({});
			} else {
				setObjToLS("grants", null);
				navigate("/enter");
				/*setAlert({
					type: "error",
					text: "An error occurred accessing your application.",
				});*/
			}
		} catch (error) {
			setObjToLS("grants", null);
			navigate("/enter");
			/*setAlert({
				type: "error",
				text: "An error occurred accessing your application.",
			});*/
		}
	};

	useEffect(() => {
		if (location?.state?.type) {
			setNotice(location.state);
		}
	}, [location]);

	return (
		<>
			<h2 style={{ marginBottom: "5px" }}>
				2025 Online Grant Application Questions
			</h2>

			{!alert.type && (
				<p style={{ fontSize: "1.15rem" }}>
					<FontAwesomeIcon icon={faUser} /> &nbsp; Signed in as {user.email}{" "}
					&nbsp; [ <Link to="/signout">sign out</Link> ]
				</p>
			)}

			<div className="grants form-container">
				{alert.type ? (
					<Alert data={alert} />
				) : (
					<div className="scholarship">
						{notice.type && <Notice data={notice} />}

						{status.submitted ? (
							<Submitted />
						) : status.readyToSubmit ? (
							<ReadyToSubmit sections={sections} status={status} />
						) : (
							<Table sections={sections} status={status} />
						)}

						<p style={{ marginTop: "25px" }}>
							Any questions regarding grants may be directed to{" "}
							<a href="mailto:director@childrenshealthcarecharity.org">
								director@childrenshealthcarecharity.org
							</a>
							.
						</p>
					</div>
				)}
			</div>
		</>
	);
};

export default Home;
