import React from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCheckCircle,
	faExclamationCircle,
} from "@fortawesome/pro-light-svg-icons";

const Notice = ({ data }) => {
	const type = data.type ? data.type : "";
	const text = data.text ? data.text : "";

	return (
		<div className={`notice ${type}`}>
			<FontAwesomeIcon
				icon={type === "success" ? faCheckCircle : faExclamationCircle}
				size="2x"
			/>
			<span>{text}</span>
		</div>
	);
};

export default Notice;
