import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { API_URL_GRANTS as API_URL, setObjToLS } from "../../utils/helpers";

import Alert from "../../components/Alert";

const Returning = () => {
	const navigate = useNavigate();
	const { code } = useParams();
	const initRef = useRef(false);

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;

		if (code) {
			init(code);
		} else {
			navigate("/enter");
		}
	});

	const init = async (code) => {
		const url = new URL(`${API_URL}/`);
		const params = {
			f: "returningInit",
			code,
			x: uuidv4(),
		};

		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url.href, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setObjToLS("grants", {
					token: json.token,
					id: json.auth.id,
					email: json.auth.email,
				});
				navigate("/");
			} else {
				navigate("/enter");
			}
		} catch (error) {
			navigate("/enter");
		}
	};

	return <Alert data={{ type: "working", text: "" }} />;
};

export default Returning;
