import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { API_URL_GRANTS as API_URL, getObjFromLS } from "../../utils/helpers";

import Alert from "../../components/Alert";
import Input from "../../components/Input";
import Msg from "../../components/Msg";
import Submit from "../../components/Submit";

import Back from "./Back";

import {
	faChevronCircleRight,
	faArrowUpRightFromSquare,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SubmitToCHC = ({ cares }) => {
	const [user, setUser] = useState({});
	const [, setNames] = useState({});
	const [errors, setErrors] = useState([]);
	const [msg, setMsg] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const formElement = useRef();
	const navigate = useNavigate();
	const initRef = useRef(false);

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;

		if (getObjFromLS("grants")) {
			const obj = getObjFromLS("grants");

			init(obj);
		} else {
			navigate("/enter");
		}
	});

	const init = async ({ token, id, email }) => {
		const url = new URL(`${API_URL}/`);
		const params = {
			admin: 1,
			f: "startedInit",
			token,
			x: uuidv4(),
		};

		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url.href, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				if (json.status.readyToSubmit) {
					setUser({
						token,
						id,
						email,
					});

					setAlert({});
				} else {
					setAlert({
						type: "error",
						text: "Application is not completed.",
					});
				}
			} else {
				setAlert({
					type: "error",
					text: "An error occurred accessing your application.",
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error occurred accessing your application.",
			});
		}
	};

	const handleUpdate = (name, value) => {
		setNames((names) => ({ ...names, [name]: value ? value : "" }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?admin=1&f=submit`);
		let formData = new FormData(formElement.current);
		formData.append("token", user.token);

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				navigate("/", {
					state: {
						type: "success",
						text: json.text,
					},
				});
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			<Back />
			<h2>Submit your completed application</h2>
			<p>
				Thank you for satisfying all the requirements of the grant application,
				if you are finished with your application, please notify Children’s
				Healthcare Charity by clicking the submit application button below.
			</p>

			<div className="grants form-container" style={{ marginTop: "15px" }}>
				{alert.type ? (
					<Alert data={alert} />
				) : (
					<>
						<p>
							Please review your application for proper format and accuracy
							before submitting as complete (opens in new window).
						</p>

						<p style={{ marginBottom: "15px" }}>
							<a
								href={`${API_URL}/?admin=1&f=print&token=${
									user.token
								}&x=${uuidv4()}`}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon icon={faArrowUpRightFromSquare} size="lg" />
								<span style={{ paddingLeft: "10px" }}></span>
								Review Application Answers
							</a>
						</p>

						<form
							method="post"
							action="/"
							onSubmit={(e) => handleSubmit(e)}
							ref={formElement}
						>
							<h3>
								Enter any notes or comments you wish to include with your
								application
							</h3>

							<div className="one-full">
								<div>
									<Input
										type="text"
										label=""
										req={false}
										name="notes"
										value={""}
										update={handleUpdate}
										errors={errors}
									/>
								</div>
							</div>

							<div className="msg-submit">
								{msg.type && <Msg data={msg} />}

								<div
									className={
										msg.type === "working" ? "hidden" : "submit-container"
									}
								>
									<Submit
										name="Submit Application"
										icon={faChevronCircleRight}
										cares={cares}
									/>
								</div>
							</div>
						</form>
					</>
				)}
			</div>
		</>
	);
};

export default SubmitToCHC;
