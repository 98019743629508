import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
	API_URL_GRANTS as API_URL,
	STATES,
	getObjFromLS,
} from "../../../utils/helpers";

import Alert from "../../../components/Alert";
import Input from "../../../components/Input";
import Msg from "../../../components/Msg";
import Submit from "../../../components/Submit";

import Back from "../Back";

import Fields1 from "./Fields1";
import Fields2 from "./Fields2";
import Fields3 from "./Fields3";
import Fields4 from "./Fields4";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const Quest1 = ({ cares }) => {
	const [user, setUser] = useState({});
	const [names, setNames] = useState({});
	const [errors, setErrors] = useState([]);
	const [msg, setMsg] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const formElement = useRef();
	const navigate = useNavigate();
	const initRef = useRef(false);

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;

		if (getObjFromLS("grants")) {
			const obj = getObjFromLS("grants");

			init(obj);
		} else {
			navigate("/enter");
		}
	});

	const init = async ({ token, id, email }) => {
		const url = new URL(`${API_URL}/`);
		const params = {
			admin: 1,
			f: "startedInit",
			token,
			x: uuidv4(),
		};

		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url.href, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setUser({
					token,
					id,
					email,
				});

				const section = json.sections.find(
					(section) => section.key === "quest1"
				);

				setNames((names) => ({
					...names,
					...json.data.quest1,
					descr: section.descr,
				}));

				setAlert({});
			} else {
				setAlert({
					type: "error",
					text: "An error occurred accessing your application.",
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error occurred accessing your application.",
			});
		}
	};

	const handleUpdate = (name, value) => {
		setNames((names) => ({ ...names, [name]: value ? value : "" }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?admin=1&f=quest1`);
		let formData = new FormData(formElement.current);
		formData.append("token", user.token);

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				navigate("/", {
					state: {
						type: "success",
						text: json.text,
					},
				});
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			<Back />
			<h2>{names.descr ? names.descr : ""}</h2>
			<p>
				Entry is required in <strong>ALL</strong>{" "}
				<span style={{ color: "var(--error)" }}>*</span> fields to complete this
				section. We have designed the application as such that you can return
				later to update your details, but please note that an entry (you may
				indicate “not applicable”) is required in each{" "}
				<span style={{ color: "var(--error)" }}>*</span> field in order to save
				your progress.
			</p>

			<div className="grants form-container">
				{alert.type ? (
					<Alert data={alert} />
				) : (
					<form
						method="post"
						action="/"
						onSubmit={(e) => handleSubmit(e)}
						ref={formElement}
					>
						<h3>Organization Details</h3>

						<Fields1
							names={{ orgName: names.orgName, ein: names.ein }}
							handleUpdate={handleUpdate}
							errors={errors}
						/>

						<p
							className="line"
							style={{ marginTop: "10px", marginBottom: "0" }}
						/>

						<Fields2
							names={{
								address: names.address,
								city: names.city,
								st: names.st,
								zip: names.zip,
								phone: names.phone,
								web: names.web,
							}}
							handleUpdate={handleUpdate}
							errors={errors}
							STATES={STATES}
						/>

						<p
							className="line"
							style={{ marginTop: "10px", marginBottom: "15px" }}
						/>

						<h3>Executive Director</h3>

						<Fields3
							names={{
								execDirName: names.execDirName,
								execDirEmail: names.execDirEmail,
								execDirPhone: names.execDirPhone,
							}}
							handleUpdate={handleUpdate}
							errors={errors}
						/>

						<p
							className="line"
							style={{ marginTop: "10px", marginBottom: "15px" }}
						/>

						<h3>Contact Person</h3>

						<Fields4
							names={{
								contactName: names.contactName,
								contactTitle: names.contactTitle,
								contactEmail: names.contactEmail,
								contactPhone: names.contactPhone,
							}}
							handleUpdate={handleUpdate}
							errors={errors}
						/>

						<p
							className="line"
							style={{ marginTop: "10px", marginBottom: "15px" }}
						/>

						<h3>Number of children impacted by your organization</h3>

						<div className="two">
							<div>
								<Input
									type="text"
									label="Total Number of Children Impacted"
									req={true}
									name="children"
									value={names.children || ""}
									update={handleUpdate}
									errors={errors}
									note="Enter only a single number for children impacted, if none,
									enter “0”"
								/>
							</div>
							<div className="empty" />
						</div>

						<div className="msg-submit">
							{msg.type && <Msg data={msg} />}

							<div
								className={
									msg.type === "working" ? "hidden" : "submit-container"
								}
							>
								<Submit
									name="Submit Answers"
									icon={faChevronCircleRight}
									cares={cares}
								/>
							</div>
						</div>
					</form>
				)}
			</div>
		</>
	);
};

export default Quest1;
